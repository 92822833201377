// LEAD LIST BORDER RADIUS FUNCTION : ()


function borderradius() {
  document.getElementById('head').style.borderRadius = "20px 20px 0px 0px";
}

function borderradius1() {
  document.getElementById('head1').style.borderRadius = "20px 20px 0px 0px";
}

function borderradius2() {
  document.getElementById('head2').style.borderRadius = "20px 20px 0px 0px";
}

function borderradius3() {
  document.getElementById('head3').style.borderRadius = "20px 20px 0px 0px";
}

function borderradius4() {
  document.getElementById('head4').style.borderRadius = "20px 20px 0px 0px";
}


// LEAD LIST OPEN/CLOSE BUTTON : ()


function openDropdown() {

  const btn = document.getElementById('btn');

  if (btn.value == "Close All") btn.value = "Open All";
  else btn.value = "Close All";

  var showBtn = document.getElementById("head");
  var showBtn1 = document.getElementById("head1");
  var showBtn2 = document.getElementById("head2");
  var showBtn3 = document.getElementById("head3");
  var showBtn4 = document.getElementById("head4");

  showBtn.click();
  showBtn1.click();
  showBtn2.click();
  showBtn3.click();
  showBtn4.click();
}

function showPopUp() {
  document.getElementById("modal").style.display = "block";
  console.log("workinggg");
}


// SIDEBAR
function openNav() {
  document.getElementById("mySidebar").style.paddingLeft = "20px";
  document.getElementById("mySidebar").style.display = "block";
  document.getElementById("main").style.display = "none";

}

function closeNav() {
  document.getElementById("mySidebar").style.paddingLeft = "0px";
  document.getElementById("mySidebar").style.display = "none";
  document.getElementById("main").style.display = "block";
}



// UPLOAD EXCEL : 1. MOTOR 2.MOTOR 

function update() {
  var select = document.getElementById('dpt');
  var value = select.options[select.selectedIndex].value;

  if (value == "Motor") {
    document.getElementById("motor").style.display = "block";
    document.getElementById("box").style.display = "block";
    document.getElementById("nonMotor").style.display = "none";
    document.getElementById("department").style.display = "none";
  } else {
    document.getElementById("nonMotor").style.display = "block";
    document.getElementById("box").style.display = "block";
    document.getElementById("motor").style.display = "none";
    document.getElementById("department").style.display = "none";
  }
}





// to show data in upload history

// function show_now() {
//   var in_date = new Date();
//   var str = in_date.getDate() + '/' + (in_date.getMonth() + 1) + '/' + in_date.getFullYear()
//   document.getElementById('display').innerHTML = str
// }

// function show_now1() {
//   var in_date = new Date();
//   var str = in_date.getDate() + '/' + (in_date.getMonth() + 1) + '/' + in_date.getFullYear()
//   document.getElementById('display').innerHTML = str

// }



// CREATE LEADS POLICY AND ENDORSEMENT BTN IN MOTOR:


function changeBtn() {
  var el = document.getElementById("BtnValue");
  if (el.textContent == "Policy") {
    el.textContent = "Endorsement";
    el.style.backgroundColor = "#f84464";
    document.getElementById("motor_entry").style.display="none";
    document.getElementById("endorsement").style.display="block";changeBtn
    document.getElementById("changeBtn").style.display="block";

  } else {
    el.textContent = "Policy";
    el.style.backgroundColor = "#575c9d";
    document.getElementById("motor_entry").style.display="block";
    document.getElementById("endorsement").style.display="none";
  }


}





// NON MOTOR CHANGE BTN:










































